<template>
    <vx-card style="padding-left: 10px">
    <div class="vx-col lg:w-1/2" align="left">
    </div>

    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :data="data"
        @search="handleSearch"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div slot="header" class="flex flex-wrap-reverse flex-grow justify-between">
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
        </div>

        <template slot="thead">
          <vs-th>Name</vs-th>
          <vs-th>Timing of treatment</vs-th>
          <vs-th>Doctor Consultation Required</vs-th>
          <vs-th>Is Active</vs-th>
          <vs-th>Actions</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].name">
              {{
              data[indextr].name
              }}
            </vs-td>
            <vs-td :data="data[indextr].treatmentTiming">
              {{
              data[indextr].treatmentTiming || "N/A"
              }}
            </vs-td>
            <vs-td :data="data[indextr].isDoctorConsultationRequired">
              {{
              data[indextr].isDoctorConsultationRequired ? 'Yes' : 'No'
              }}
            </vs-td>
            <vs-td :data="data[indextr].isActive">
              {{
              data[indextr].isActive ? 'Yes' : 'No'
              }}
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="Edit Timing of Treatment" position="left" >

                <vs-button
                  type="border"
                  size="small"
                  @click="editTreatmentTiming(data[indextr])"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="mr-2"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </vx-card>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: "TreatmentTypeList",
    data() {
        return {
            data: [],
            dataTableParams: {
                search: ""
            },
            limitOptions: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "25", value: 25 },
                { text: "50", value: 50 },
                { text: "100", value: 100 }
            ],
            noDataText: "",
            awaitingSearch: null,
            routeType: ""
        }
    },
    methods: {
        ...mapActions('treatmentTypes', ['treatmentTypes']),
        fetchTreatmentTypes() {
            this.$vs.loading();
            this.treatmentTypes(this.dataTableParams).then(res => {
                this.data = res.data.data;
                this.noDataText = "";
                this.$vs.loading.close();
            }).catch(err => {
                this.noDataText = "";
                this.$vs.loading.close();
            })
        },
        handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        this.awaitingSearch = setTimeout(() => {
          this.dataTableParams.search = searching;
          this.fetchTreatmentTypes();
        }, 500);
      },
      editTreatmentTiming(dataToEdit) {
          this.$router.push(`/${this.routeType}/treatment-types/${dataToEdit._id}/edit`)
      }
    },
    created() {
        this.noDataText = "Fetching ...";

        if(this.$store.state.AppActiveUser.userType === "superAdmin")
            this.routeType = "super-admin";
        else
            this.routeType = "admin";

        this.fetchTreatmentTypes();
    }
}
</script>